import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

//解决路由导航到统一路径重复报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => console.log(err))
}


const routes = [
  {
    path: '/',
    redirect: '/workOutList'
  },
  {
    path: '/workOutList',
    name: 'workOutList',
    component: () => import("../pages/workOutList.vue"),
    meta: { 
        fullPath: "/workOutList",
        name: "出差数据",
    },
  },
  {
    path: '/workOverList',
    name: 'workOverList',
    component: () => import("../pages/workOverList.vue"),
    meta: { 
        fullPath: "/workOverList",
        name: "加班数据",
    },
  },
  {
    path: '/workOutKeep',
    name: 'workOutKeep',
    component: () => import("../pages/workOutKeep.vue"),
    meta: { 
        fullPath: "/workOutKeep",
        name: "管理员维护",
    },
  },
  
  
  
]

const router = new VueRouter({
  routes,
});

export default router;
