import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/base.css"

import "./utils/pxrem"
Vue.use(ElementUI);



// 全局后置的路由守卫 初始化时候被调用，每次路由切换之后被调用
router.afterEach((to,from) => {//全局的后置钩子函数  一般用来显示标题，当前路由页面结束的一些功能
  document.title=to.meta.name ||"雨润集团"
})






Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
  // bus总线的写法
  beforeCreate() {
    Vue.prototype.$bus = this //将bus添加到Vue添加构造函数的原型 全局事件总线
    
  }
}).$mount("#app");
